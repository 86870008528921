import { ColorScheme, MantineThemeOverride, rem } from '@mantine/core';

export const getMantineThemeOverride = (overrides?: {
    colorScheme?: ColorScheme;
    components?: Partial<MantineThemeOverride['components']>;
}): MantineThemeOverride => ({
    ...overrides,

    focusRing: 'auto',

    //Black value from Blueprint. We could change this.
    // Without it things look a little darker than before.
    black: '#111418',

    spacing: {
        one: rem(1),
        two: rem(2),
        xxs: rem(4),
        xs: rem(8),
        sm: rem(12),
        md: rem(16),
        lg: rem(20),
        xl: rem(24),
        xxl: rem(32),
        '3xl': rem(40),
        '4xl': rem(48),
        '5xl': rem(64),
        '6xl': rem(80),
        '7xl': rem(96),
        '8xl': rem(128),
        '9xl': rem(160),
    },

    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Open Sans',
        'Helvetica Neue',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'sans-serif',
    ].join(', '),

    lineHeight: 1.4,

    cursorType: 'pointer',

    components: {
        Kbd: {
            styles: (theme, _params) => ({
                root: {
                    borderBottomWidth: theme.spacing.two,
                },
            }),
        },

        Tooltip: {
            defaultProps: {
                withArrow: true,
            },
        },

        Modal: {
            defaultProps: {
                // FIXME: This makes the mantine modals line up exactly with the Blueprint ones.
                // It could be made a less-magic number once we migrate
                yOffset: 140,
            },
        },

        Alert: {
            styles: (_theme, _params) => ({
                title: {
                    // FIXME: This makes the icon align with the title.
                    lineHeight: 1.55,
                },
            }),
        },
        ...overrides?.components,
    },

    globalStyles: (theme) => ({
        'html, body': {
            backgroundColor: theme.colors.gray[0],
        },

        body: {
            fontSize: '14px',
        },

        p: {
            marginBottom: '10px',
            marginTop: 0,
        },

        b: {
            fontWeight: 'bold',
        },

        strong: {
            fontWeight: 600,
        },

        '.react-draggable.react-draggable-dragging .tile-base': {
            border: `1px solid ${theme.colors.blue[5]}`,
        },

        '.ace_editor.ace_autocomplete': {
            width: '500px',
        },
    }),
});
